import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const PasswordReset = props => {
  const [email, setEmail] = useState("")
  const handlePasswordReset = e => {
    console.log("Reset bruuuh")
  }
  return (
    <Layout>
      <SEO title="Mot de passe oublié" />
      <div className="flex justify-center">
        <img
          src={require("../images/slide-page.jpg")}
          className="w-full shadow"
          style={{ height: "auto", width: "100%" }}
          alt=""
        />
      </div>
      <div className="flex flex-wrap py-6">
        <div className="w-1/5"></div>
        <div className="w-3/5">
          <form
            onSubmit={handlePasswordReset}
            className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
          >
            <div className="text-center text-2xl py-5">
              REINITIALISATION DE MOT DE PASSE
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email-address"
              >
                Adresse email
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email-address"
                type="email"
                name="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                REINITIALISER MON MOT DE PASSE
              </button>
            </div>
            <div className="text-center text-xs pt-10">
              Vous avez changé d'avis ?<br />
              <Link
                to="/login"
                className="text-blue-600 hover:text-blue-800 font-bold"
              >
                Connectez-vous
              </Link>
              &nbsp; ou &nbsp;
              <Link
                to="/register"
                className="text-green-600 hover:text-red-800 font-bold"
              >
                Inscrivez-vous
              </Link>
            </div>
          </form>
        </div>
        <div className="w-1/5"></div>
      </div>
    </Layout>
  )
}

export default PasswordReset
